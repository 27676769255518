<template>
	<div class="flex items-center h-full py-20">
		<div class="w-72 mx-auto border border-gray-200 rounded-md p-4 shadow-md">
			<LoginForm show autofocus :isForgot="isForgot" />
		</div>
	</div>
</template>

<script>
	import LoginForm from '~/components/LoginForm'
    export default {
		components: {
			LoginForm,
		},
		data() {
			return {
				isForgot: this.$route.path.includes('forgot-password')
			}
		},
    }
</script>
